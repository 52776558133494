import React from 'react'

const Welcome = () => {
    return (
        <div style={{ height: '100%', textAlign: 'center' }}>

            <div style={{ fontSize: '18px', paddingTop: '24px', width: '75%', paddingLeft: '12.5%' }}>
                Вы не выбрали диалог.
            </div>

        </div>
    )
}

export default Welcome
